//check for svg
var SVGsupport = !!document.createElementNS && !!document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect;

if (!SVGsupport) {
	document.getElementsByTagName('html')[0].className += ' no-svg';
}

$(document).ready(function () {
	if (window.location.hash) {
		var hash = $('' + window.location.hash + '');
		scrollToElement(hash, 0);
	}

	fixedHeader();

	//svg replacement
	if (!SVGsupport) {
		$('img[src$="svg"]').each(function () {
			if ($(this).data('image') != 'undefined') {
				$(this).attr('src', $(this).data('image'));
			}
		});
	}

	// Slick slider
	if ($('.js-slider').length) {
		$('.js-slider').each(function () {
			var $this = $(this);
			$this.slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
				arrows: false,
				adaptiveHeight: true,
				autoplay: true,
				autoplaySpeed: 3000,
				pauseOnFocus: true,
				pauseOnHover: true,
				pauseOnDotsHover: true
			});
			$this.on('afterChange', function (event, slick, currentSlide, nextSlide) {
				$('iframe', $this).each(function () {
					$(this)[0].contentWindow.postMessage(
						'{"event":"command","func":"' + 'stopVideo' + '","args":""}',
						'*'
					);
				});
			});
		});
	}

	// Table responsive
	if ($('table').length) {
		$('table').each(function () {
			var table = $(this),
				tableHeading = table.find('th'),
				tableLength = table.find('th').length;

			table.find('tr').not(':eq(0)').each(function () {
				var tableRow = $(this);

				tableRow.find('td').each(function () {
					var tableCell = $(this),
						tableCellIndex = tableCell.index(),
						tableRowLenght = tableRow.find('td').length,
						colspan = 0;

					if (tableRowLenght < tableLength) {
						colspan = 1;
					}

					if (tableHeading.eq(tableCellIndex + colspan).text() != '') {
						tableCell.attr('data-type', '' + tableHeading.eq(tableCellIndex + colspan).text() + '');
					} else {
						tableCell.addClass('table-category');
					}
				});
			});
		});
	}

	if ($('.js-select').length && $('html').hasClass('no-touch')) {
		$('.js-select').each(function () {
			$(this).select2({
				dropdownParent: $(this).parent()
			});
		});
	}

	if ($('.js-gallery').length) {

		$('.js-gallery').each(function () {
			$(this).lightGallery({
				share: false,
				download: false
			});
		});
	}
	if(!!window.IntersectionObserver && $('.js-marker').length){
		let observer = new IntersectionObserver((entries, observer) => { 
		  entries.forEach(entry => {
		  if(entry.isIntersecting ){
			$('html').removeClass('button-fixed')
		  } else {
			  if($('.main-header').hasClass('fixed-container') || $('.main-header').hasClass('fixed-nav')){
				if(window.innerWidth<992){
	
					$('html').addClass('button-fixed');
				}  else {
					$('html').removeClass('button-fixed')
				}
			} else {				
				$('html').removeClass('button-fixed')
			  }
		  } 
		  });
		});
		observer.observe(document.querySelector('.js-marker'));
	  }
});

$(document).on('change', 'select', function () {
	if (!$(this).hasClass('changed')) {
		$(this).addClass('changed');
	}
});

// Accordion
$(document).on('click', '.js-accordion-btn', function () {
	var $this = $(this);
	$this.toggleClass('active');
	$('.js-accordion-toggle', $this.parent()).stop().slideToggle();
});

// Toggle hidden content
$(document).on('click', '.js-toggle-hidden-content-btn', function () {
	var $this = $(this);
	$this.toggleClass('active');
	$('.js-hidden-content', $this.parents('.js-box-container')).stop().slideToggle();
});

// Hide hidden content
$(document).on('click', '.js-close-hidden-content', function () {
	$(this).parents('.js-hidden-content').slideUp();
});
// Toggle hidden popup
$(document).on('click', '.js-toggle-hidden-popup-btn', function () {
	var $this = $(this);
	$('body').addClass('with-overlay');
	$('.js-hidden-popup', $this.parents('.js-box-container')).show();
});

// Hide hidden popup
$(document).on('click', '.js-close-popup-content', function () {
	var $this = $(this);
	$('body').removeClass('with-overlay');
	$this.parents('.js-hidden-popup').hide();

	$('.video-embed iframe', $this.parents('.js-hidden-popup'))[0].contentWindow.postMessage(
		'{"event":"command","func":"' + 'stopVideo' + '","args":""}',
		'*'
	);
});

// Sandwich menu
$(document).on('click', '.js-sandwich-button', function () {
	$(this).parents('.js-menu-container').toggleClass('menu-open');
	// $('.menu').stop().fadeToggle();
});

// Dropdown menu
$(document).on('click', '.js-menu-dropdown-btn', function () {
	var $this = $(this),
		menuItem = $this.parents('.js-menu-item');

	if (menuItem.hasClass('active')) {
		menuItem.removeClass('active');
		$('.js-menu-dropdown', menuItem).stop().slideUp();
	} else {
		$('.js-menu-item.active .js-menu-dropdown').stop().slideUp();
		$('.js-menu-item.active').removeClass('active');
		menuItem.addClass('active');
		$('.js-menu-dropdown', menuItem).stop().slideDown();

	}
});

// Toggle search filter button
$(document).on('click', '.js-search-filter-toggle-button', function () {
	var $this = $(this),
		searchFilter = $this.parents('.js-search-filter'),
		disabledItem = searchFilter.find('.search-filter__list-item.disabled');

	if (searchFilter.hasClass('show-disabled')) {
		disabledItem.stop().fadeOut(function () {
			hideLoader();
		});
		searchFilter.removeClass('show-disabled');
	} else {
		disabledItem
			.stop()
			.fadeIn(function () {
				hideLoader();
			})
			.css('display', 'flex');
		searchFilter.addClass('show-disabled');
	}
});

// Search Filter dropdown
$(document).on('click', '.js-search-filter-dropdown-button', function () {
	var $this = $(this),
		searchFilter = $this.parents('.js-search-filter'),
		searchFilterContainer = searchFilter.find('.js-search-filter-container');

	searchFilterContainer.stop().slideToggle();
});

$(window).scroll(function () {
	fixedHeader();
});

$(document).on('click mouseover', '.js-tooltip-popup-button', function () {
	var ref = $(this),
		popup = $('.js-tooltip-popup-content', ref.parent());
	if (popup.is(':visible')) {
		popup.hide();
	} else {
		$('.js-tooltip-popup-content').hide();
		popup.stop(true, true).fadeToggle('fast');
		var popper = new Popper(ref, popup, {
			placement: 'auto',
			modifiers: {
				preventOverflow: {
					boundariesElement: 'viewport'
				}
			}
		});
	}
});

$(document).on('mouseleave', '.js-tooltip-popup-button', function () {
	var ref = $(this),
		popup = $('.js-tooltip-popup-content', ref.parent());
	if (popup.is(':visible')) {
		popup.hide();
	}
});


// open video popup
$(document).on('click', '.js-open-video-popup', function () {
	// event.preventDefault();

	var $this = $(this),
		videoSrc = $this.data('video-src'),
		videoPopup = $('.js-video-popup', $this.parent()),
		videoIframe = videoPopup.find('.js-video-popup-iframe');

	videoIframe.prop('src', videoSrc);
	videoPopup.fadeIn();
});

// close video popup
$(document).on('click', '.js-close-video-popup', function () {

	var $this = $(this),
		videoPopup = $this.parents('.js-video-popup'),
		videoIframe = videoPopup.find('.js-video-popup-iframe');

	videoPopup.fadeOut(function () {
		videoIframe.prop('src', '');
	});
});

// Smooth scrolling on anchor link
$(document).on('click', 'a[href^="#"]', function (event) {
	event.preventDefault();
	var anchor = $($.attr(this, 'href'));
	scrollToElement(anchor, null, $(this));
});

// Show loader
function showLoader() {
	$('.js-loader').addClass('active');
}

// Hide loader
function hideLoader() {
	$('.js-loader').removeClass('active');
}

// Scroll to element
function scrollToElement(element, speed, anchor) {
	if (speed === null || speed === undefined) {
		speed = 500;
	}

	var headerHeight = $('.header-nav').outerHeight();

	if ($(window).innerWidth() > 640) {
		headerHeight = $('.main-header').outerHeight();
	}

	$('html, body').animate({
		scrollTop: element.offset().top - headerHeight

	}, speed);
	if (anchor) {

		if (anchor.hasClass('js-text-nav-link')) {
			window.location.href = anchor.attr('href');
		}
	}
}


function fixedHeader() {
	var scroll = $(window).scrollTop(),
		header = $('.main-header'),
		headerPosition = header.position().top,
		headerContainer = $('.main-header__container'),
		headerContainerHeight = headerContainer.outerHeight(),
		headerContentHeight = $('.header-content').outerHeight(),
		headerNav = $('.header-nav'),
		headerNavHeight = headerNav.outerHeight();
	var textNavSidebar = $('.js-text-na-sidebar');
	var mobileLink = $('.js-mobile-link');
	if ($(window).innerWidth() > 640) {
		if (header.hasClass('fixed-nav')) {
			header.removeClass('fixed-nav')
		}
		if (scroll > headerPosition) {
			header.css('padding-bottom', headerContainerHeight + 'px');
			header.addClass('fixed-container');
			if (textNavSidebar) {
				textNavSidebar.css('top', headerContainerHeight + 20 + 'px');
				mobileLink.css('top', headerContainerHeight + 'px');
			}
		} else {
			header.css('padding-bottom', '0');
			header.removeClass('fixed-container');
		}
	} else {
		if (header.hasClass('fixed-container')) {
			header.removeClass('fixed-container')
		}

		if (scroll > (headerPosition + headerContentHeight)) {
			header.css('padding-bottom', headerNavHeight + 'px');
			header.addClass('fixed-nav');
			if (textNavSidebar) {
				textNavSidebar.css('top', headerNavHeight + 20 + 'px');
				mobileLink.css('top', headerNavHeight + 'px');
			}
		} else {
			header.css('padding-bottom', '0');
			header.removeClass('fixed-nav');
		}
	}
}

